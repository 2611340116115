(function ($) {

    $('.home__partners__rep-js').slick({
        dots: false,
        autoplaySpeed: 1400,
        arrows: false,
        infinite: true,
        speed: 1400,
        autoplay: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    });

})(jQuery);