jQuery(document).ready(function ($) {

    $('.home-slider').slick({
        dots: false,
        speed: 1000,
        infinite: true,
        autoplay: false,
        cssEase: 'ease-out',
        arrows: true,
        fade: false,
        prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-angle-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="fa fa-angle-right"></i></button>',
    });

    $('.home-products__slider').slick({
        dots: false,
        speed: 1000,
        autoplaySpeed: 3000,
        infinite: true,
        autoplay: true,
        cssEase: 'ease-out',
        arrows: true,
        fade: false,
        prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-angle-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="fa fa-angle-right"></i></button>',
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    });

    $('.product__slider--main').slick({
        dots: false,
        speed: 1000,
        infinite: true,
        autoplay: false,
        cssEase: 'ease-out',
        arrows: false,
        fade: true,
        responsive: [
            {
                breakpoint: 640,
                settings: "unslick",
            },
        ],
    });

    $('.product__slider--nav').slick({
        dots: false,
        speed: 1000,
        infinite: true,
        autoplay: false,
        cssEase: 'ease-out',
        arrows: false,
        fade: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        focusOnSelect: true,
        asNavFor: '.product__slider--main',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                }
            }
        ],
    });


});
